import { useEffect, useRef, useState } from 'react';
import { COLLAPSED_ROW_ESTIMATED_SIZE_PX } from 'logic/ui-active-session';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';
import { useStore } from 'logic';
import ScrollTopIcon from '@mui/icons-material/VerticalAlignTop';
import ScrollBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import { Fab, Box, FabProps, Tooltip } from '@mui/material';
import ExpandedIcon from '@mui/icons-material/Rectangle';
import CollapseIcon from '@mui/icons-material/TableRows';
import { MeasurableContainer } from './MeasurableContainer';

interface QueueListProps {
  numItems: number;
  renderQueueItem: (index: number) => JSX.Element;
}

export const QueueList = ({ numItems, renderQueueItem }: QueueListProps) => {
  const cache = useRef(
    new CellMeasurerCache({
      defaultHeight: COLLAPSED_ROW_ESTIMATED_SIZE_PX,
      fixedWidth: true,
    })
  );

  const {
    uiStickyHeaders: { setScrollPosition },
    uiQueueScroll: { setList },
    uiActiveSessionStore: { areCardsCollapsed, toggleCardView },
  } = useStore();

  const [listRef, setListRef] = useState<List | null>(null);

  // This is necessary to enable drag/drop reordering before first scrolling...
  useEffect(() => {
    const timer = setTimeout(() => {
      listRef?.scrollToPosition(1);
      setTimeout(() => {
        listRef?.scrollToPosition(0);
      }, 0);
    }, 150);

    return () => clearTimeout(timer);
  }, []);

  const fabProps: FabProps = {
    sx: {
      position: 'absolute',
      bottom: (t) => t.spacing(2),
      left: (t) => t.spacing(1),
      zIndex: 1250,
    },
    variant: 'extended',
    size: 'small',
    color: 'default',
  };

  return (
    <>
      <AutoSizer>
        {({ width, height }) => (
          <List
            ref={(ref) => {
              setListRef(ref);
              setList(ref);
            }}
            onScroll={({ scrollTop }) => setScrollPosition(scrollTop)}
            estimatedRowSize={COLLAPSED_ROW_ESTIMATED_SIZE_PX}
            width={width}
            height={height}
            rowCount={numItems}
            deferredMeasurementCache={cache.current}
            rowHeight={cache.current.rowHeight}
            rowRenderer={({ index, key, parent, style }) => (
              <CellMeasurer
                cache={cache.current}
                columnIndex={0}
                key={key}
                rowIndex={index}
                parent={parent}
              >
                {({ measure }) => (
                  <div style={style}>
                    <MeasurableContainer onResize={measure}>
                      {renderQueueItem(index)}
                    </MeasurableContainer>
                  </div>
                )}
              </CellMeasurer>
            )}
          />
        )}
      </AutoSizer>
      <Fab {...fabProps}>
        <Box pr={1} pl={1} pt={0.5} onClick={() => listRef?.scrollToRow(0)}>
          <ScrollTopIcon />
        </Box>
        |
        <Box pl={1} pr={1} pt={0.5} onClick={() => listRef?.scrollToRow(-1)}>
          <ScrollBottomIcon />
        </Box>
      </Fab>
      <Fab
        {...fabProps}
        sx={{
          ...fabProps.sx,
          left: (t) => t.spacing(14),
        }}
        onClick={toggleCardView}
      >
        <Tooltip title={areCardsCollapsed ? 'Expand Cards (X)' : 'Collapse Cards (X)'}>
          {areCardsCollapsed ? <ExpandedIcon /> : <CollapseIcon />}
        </Tooltip>
      </Fab>
    </>
  );
};
