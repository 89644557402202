import { action, makeObservable, observable, reaction } from 'mobx';
import type ft from 'firebase';

import { FirestoreUserSettings } from '@creative-kit/shared';
import type { RootStore } from '.';
import { db } from './data';

export class UserSettingsStore {
  private stopListeningToUserSettings = () => {};
  private rootStore: RootStore;
  private ref?: ft.firestore.DocumentReference<FirestoreUserSettings>;
  userSettings: FirestoreUserSettings = {};

  constructor(rootStore: RootStore) {
    makeObservable(this, {
      userSettings: observable,
      updateUserSettings: action.bound,
      resetUserSettings: action,
    });

    this.rootStore = rootStore;
  }

  init() {
    reaction(
      () => [this.rootStore.sessionsStore.currentSession, this.rootStore.authStore.user],
      this.watchUserSettings,
      { fireImmediately: true }
    );
  }

  watchUserSettings = () => {
    const { currentSession } = this.rootStore.sessionsStore;
    this.stopListeningToUserSettings();
    this.resetUserSettings();
    this.ref = undefined;
    if (currentSession && this.rootStore.authStore.user) {
      this.ref = db.sessionUserSettings(currentSession.id, this.rootStore.authStore.user.uid);
      this.stopListeningToUserSettings = db
        .sessionUserSettings(currentSession.id, this.rootStore.authStore.user.uid)
        .onSnapshot(this.updateUserSettings);
    }
  };

  resetUserSettings() {
    this.userSettings = {};
  }

  updateUserSettings(snapshot: ft.firestore.DocumentSnapshot<FirestoreUserSettings>) {
    // Create user settings if doesn't exist
    if (!snapshot.exists) {
      this.ref?.set({}, { merge: true });
    }
    this.userSettings = snapshot.data() || {};
  }

  modifyUserSettings(updateData: Record<string, unknown>) {
    this.ref?.update(updateData);
  }
}
