import React from 'react';
import { observer } from 'mobx-react-lite';
import makeStyles from '@mui/styles/makeStyles';
import { useStore } from 'logic';
import clsx from 'clsx';
import { Source } from 'logic/source';
import { getPercentageForSize, getRemForSize } from 'logic/ui-module-connection-display';
import { Row } from 'logic/row';
import { Card } from 'logic/card';
import { ModuleRowImage } from './ModuleRowImage';
import { ModuleRowFields } from './ModuleRowFields';
import { ModuleConnectionDisplayMode, SessionModule } from '@creative-kit/shared';

const useStyles = makeStyles(() => ({
  containerGrid: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  containerCarousel: {
    display: 'inline-flex',
    overflow: 'auto',
  },
}));

interface ModuleConnectionsWrapperProps {
  source: Source;
  connections: Row[];
  module: SessionModule;
  card: Card;
}

export const ModuleConnectionsWrapper: React.FC<ModuleConnectionsWrapperProps> = observer(
  ({ module, connections, card, source }) => {
    const {
      uiModuleConnectionDisplayStore: { getConnectionDisplayMode, getConnectionsSize },
    } = useStore();
    const connDisplayMode = getConnectionDisplayMode(module.id);
    const connSize = getConnectionsSize(module.id);
    const ModuleConnection = source?.type === 'image' ? ModuleRowImage : ModuleRowFields;
    const styles = useStyles();

    const calculatedWidthStyles = () => {
      if (source.type !== 'image') {
        if (connDisplayMode === ModuleConnectionDisplayMode.Grid) {
          return { minWidth: `${getPercentageForSize(connSize)}%` };
        } else if (connDisplayMode === ModuleConnectionDisplayMode.Carousel) {
          return { minWidth: `${getRemForSize(connSize)}` };
        }
      }
      return {};
    };

    return (
      <div
        style={{ width: '100%' }}
        className={clsx({
          [styles.containerGrid]: connDisplayMode == ModuleConnectionDisplayMode.Grid,
          [styles.containerCarousel]: connDisplayMode == ModuleConnectionDisplayMode.Carousel,
        })}
      >
        {connections.map((row, index) => (
          <div
            style={{
              margin: '5px',
              ...calculatedWidthStyles(),
            }}
          >
            <ModuleConnection
              key={row.id}
              index={index}
              isEditable={card.isEditable}
              row={row}
              module={module}
              onRemove={() => card.removeRow(module.id, row.id)}
            />
          </div>
        ))}
      </div>
    );
  }
);
