import type { Field } from './fields';

export enum ModuleConnectionDisplayMode {
  Grid = 'Grid',
  Carousel = 'Carousel',
}

export enum ModuleConnectionSize {
  Full = 'Full',
  Half = 'Half',
  Compact = 'Compact',
}

export enum ModuleBucketSize {
  Full = 'Full',
  Half = 'Half',
  Compact = 'Compact',
}

export type SessionModuleDisplaySettings = {
  connectionsDisplayMode: ModuleConnectionDisplayMode;
  moduleBucketSize: ModuleBucketSize;
};

export const moduleColors = [
  '#fc5c65',
  '#fd9644',
  '#fed330',
  '#26de81',
  '#2bcbba',
  '#45aaf2',
  '#4b7bec',
  '#a55eea',
  '#d1d8e0',
  '#778ca3',
];

export enum SessionPermissions {
  EDIT = 'edit',
  VIEW = 'view',
  // TODO: is this level necessary
  PRIVATE = 'private',
}

export interface SessionModule {
  id: string;
  name: string;
  color: string;
  sourceId: string;
  multi: boolean;
  table: boolean;
  fields: string[];
}

export interface FilenameSettings {
  disableFilenameCleanup: boolean;
  disableSpecialCharacterCleanup: boolean;
  fieldSeparator: string;
  filenameCaps: boolean;
  cardNumberWidth: number;
  spaceSeparator: string;
  specialCharacterReplacement: string;
  trimSpaces: boolean;
  trimSpecialCharacters: boolean;
}

export interface FirestoreSession {
  name: string;
  createdAt: Date;
  createdBy: {
    userId: string;
    email: string | null;
  };
  deletedAt?: Date;
  isArchived?: boolean;
  userId: string;
  cardTitleFormula: Field[];
  filenameFormula: Field[];
  filenameSettings: FilenameSettings;
  automaticModuleCreation: boolean;
  automaticCardTitleSuffix: boolean;
  automaticFilenameVersioning: boolean;
  filenameParametersUpdatedAt?: Date;
  sourceIds: string[];
  modules: Record<string, SessionModule>;
  moduleIds: string[];
  cardIds: string[];
  visibility: {
    hiddenModuleIds: string[];
    hideFilename: boolean;
  };
  moduleDisplaySettings: Record<string, SessionModuleDisplaySettings>;
  publicAccessLevel: SessionPermissions;
}
