import { useMemo, useRef, useCallback } from 'react';
import {
  Paper,
  Tab,
  Tabs,
  CircularProgress,
  Box,
  Tooltip,
  IconButton,
  Button,
  ExtendButtonBase,
  TabTypeMap,
  Backdrop,
  Checkbox,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Split from 'react-split';
import { SizeMe } from 'react-sizeme';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import { DoneQueue } from 'ui/DoneQueue';
import { InProgressQueue } from 'ui/InProgressQueue';

import { observer } from 'mobx-react-lite';
import { useStore } from 'logic';
import { ModuleManager } from 'ui/ModuleManager';
import { WarningIcon } from 'ui/WarningIcon';
import { AddCardsModal } from 'ui/AddCardsModal';
import { BatchQueue } from 'ui/BatchQueue';
import { FilenameManager } from 'ui/FilenameManager';
import { CardTitleManager } from 'ui/CardTitleManager';
import { QueueTabName } from 'logic/ui-active-session';
import { QueueFilterMenu } from 'ui/QueueFilterMenu';
import { QueueViewMenu } from 'ui/QueueViewMenu';
import { SourcePane } from 'ui/SourcePane';
import { FullScreenImageModal } from 'ui/FullScreenImageModal';
import { QueueSearchSettingManager } from 'ui/QueueSearchSettingManager';
import { Helmet } from 'react-helmet';
import { QueueFolderDownloadModal } from 'ui/QueueFolderDownloadModal';
import {
  BatchOperationInProgressModal,
  BatchOperationType,
} from 'ui/BatchOperationInProgressModal';
import { useActiveSessionKbar } from 'logic/ui-session-kbar';
import { BatchCopyToClipboardModal } from 'ui/BatchCopyToClipboard';
import { useSourceFilePicker } from 'logic/ui-file-picker';
import { useKBar } from 'kbar';
import { META_KEY } from 'logic/ui-keyboard-shortcuts';
import { SearchInput } from 'ui/SearchInput';
import { useConfirm } from 'material-ui-confirm';
import { useShortcuts } from 'logic/hooks';
import { CardView } from 'views/CardView';
import { TabsTitle } from 'ui/TabsTitle';
import { SelectedQueue } from 'ui/SelectedQueue';
import { NoSessionView } from './NoSessionView';

const QueueTab = Tab as ExtendButtonBase<TabTypeMap & { props: { value: QueueTabName } }>;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingTop: theme.spacing(1),
  },
  listWrapper: {
    display: 'flex',
    flex: 1,
    minHeight: 1,
    padding: theme.spacing(1),
    paddingTop: 0,
  },
  pane: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
    position: 'relative',
  },
  fullSize: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minHeight: 0,
    position: 'relative',
  },
  collapsed: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    writingMode: 'vertical-rl',
    fontSize: 40,
    fontWeight: 500,
    textTransform: 'uppercase',
    transform: 'rotateZ(180deg)',
  },
  tabLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  tabCount: {
    color: theme.palette.text.primary,
  },
  sessionLoader: {
    display: 'flex',
    marginTop: theme.spacing(2),
    margin: '0 auto',
  },
  '@global': {
    '.gutter': {
      cursor: 'col-resize',
      backgroundColor: '#ccc',
      backgroundClip: 'content-box',
      borderLeft: '9px solid transparent',
      borderRight: '9px solid transparent',
    },
  },
}));

const InProgressCount = observer(() => {
  const {
    sessionsStore: { currentSession },
    uiActiveSessionStore: { selectedCards },
  } = useStore();

  if (!currentSession) return null;

  const { inProgressCount } = currentSession;

  if (selectedCards.length > 0) {
    return <span>{inProgressCount}</span>;
  }

  return <span>{inProgressCount}</span>;
});

const DoneCount = observer(() => {
  const {
    sessionsStore: { currentSession },
  } = useStore();

  if (!currentSession) return null;

  const { doneCount } = currentSession;

  return <span>{doneCount}</span>;
});

export const ActiveSessionView = observer(() => {
  const styles = useStyles();
  const rootStore = useStore();
  const {
    sessionsStore: { currentSession, importInProgress },
    uiCardSearchStore: { currentSearch, setCurrentSearch },
    uiBatchCardsStore: {
      activateBatchMode,
      deactivateBatchMode,
      showAddCardsButton,
      batchModeActive,
      generatedCards,
      createCards,
      exitPreview,
    },
    sessionCardActions: { removeSelectedCards },
    uiActiveSessionStore: {
      currentQueueTab,
      currentSourceTab,
      setCurrentQueueTab,
      showSelectedTab,
      hasSelectedCards,
      selectedCardIds,
      deselectAllCards,
      shouldShowQueueFilter,
      batchDeleteLoading,
      duplicationOperationLoading,
    },
    uiCardView: { isCardFocused },
    cardsStore: { cards },
    uiQueueSelector: {
      areAllCardsSelected,
      isQueueSelectorChecked,
      isQueueSelectorDisabled,
      isQueueSelectorIndeterminate,
      toggleAllCardsInTab,
    },
  } = rootStore;

  const { openSourceFilePicker, getInputProps } = useSourceFilePicker(currentSourceTab);
  useActiveSessionKbar({ openSourceFilePicker });
  const confirm = useConfirm();
  const shortcuts = useMemo(
    () => [
      {
        name: 'deleteAllSelected',
        combination: [META_KEY, 'backspace'],
        fn: async () => {
          if (selectedCardIds.length > 0) {
            try {
              await confirm({
                title: 'Delete Selected Assets',
                confirmationButtonProps: {
                  autoFocus: true,
                },
                content: `Do you want to delete ${selectedCardIds.length} cards`,
              });
              removeSelectedCards();
            } catch (e) {
              // no need to handle error
            }
          }
        },
      },
    ],
    [confirm, removeSelectedCards, selectedCardIds.length]
  );
  useShortcuts(shortcuts);
  const kbar = useKBar();
  const queueRef = useRef<HTMLDivElement>(null);

  const renderCurrentTab = () => {
    if (showSelectedTab) {
      return <SelectedQueue />;
    }

    if (currentQueueTab === QueueTabName.InProgress) {
      return (
        <>
          <InProgressQueue />
          <input {...getInputProps()} />
        </>
      );
    }

    if (currentQueueTab === QueueTabName.Done) {
      return <DoneQueue />;
    }

    if (currentQueueTab === QueueTabName.Batch) {
      return <BatchQueue />;
    }

    return null;
  };
  const toggleAllCardsSelectorCheckbox = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (areAllCardsSelected) {
        deselectAllCards();
      } else if (hasSelectedCards) {
        deselectAllCards();
      } else {
        toggleAllCardsInTab();
      }

      // So that user can use shortcuts again
      event.target.blur();
    },
    [areAllCardsSelected, cards, hasSelectedCards, toggleAllCardsInTab, deselectAllCards]
  );

  const renderActionBar = () => {
    if (currentQueueTab === QueueTabName.InProgress || currentQueueTab === QueueTabName.Done) {
      return (
        <Box
          sx={{
            marginLeft: isCardFocused ? '13px' : '17px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '& > :not(:last-child)': {
              marginRight: 1,
            },
          }}
        >
          <Checkbox
            onChange={toggleAllCardsSelectorCheckbox}
            checked={isQueueSelectorChecked}
            disabled={isQueueSelectorDisabled}
            indeterminate={isQueueSelectorIndeterminate}
            color="default"
          />
          <QueueSearchSettingManager />
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <SearchInput
              placeholder="Find Assets"
              value={currentSearch ?? ''}
              onTextChange={setCurrentSearch}
              onFocus={() => deselectAllCards()}
            />
          </Box>
          {shouldShowQueueFilter && <QueueFilterMenu />}
          <QueueViewMenu />
          {showAddCardsButton && (
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              size="small"
              onClick={() => activateBatchMode()}
            >
              Add
            </Button>
          )}
        </Box>
      );
    }

    if (currentQueueTab === QueueTabName.Batch) {
      return (
        <Paper
          square
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            p: 1,
          }}
        >
          <span>Review and</span>
          <Button variant="contained" color="primary" size="small" onClick={createCards}>
            Add Assets to Queue ({META_KEY.symbol}shift+enter)
          </Button>
          <Button variant="outlined" size="small" onClick={exitPreview}>
            Return to settings (SHIFT+ESC)
          </Button>
          <Button variant="contained" size="small" color="error" onClick={deactivateBatchMode}>
            Cancel "Add Assets"
          </Button>
        </Paper>
      );
    }
  };

  if (!currentSession) {
    return <NoSessionView />;
  }

  const {
    numFilenamesNeedingAttention,
    hasInProgressCardsWithDeletedConnections,
    hasDoneCardsWithDeletedConnections,
  } = currentSession;

  const showInProgressTab =
    currentQueueTab !== QueueTabName.Selected &&
    !(currentQueueTab === QueueTabName.Done && hasSelectedCards);
  const showDoneTab =
    currentQueueTab !== QueueTabName.Selected &&
    !(currentQueueTab === QueueTabName.InProgress && hasSelectedCards);

  const deletedConnectionsWarning = (
    <Tooltip
      title="Some connections have been deleted from their original library – Delete the Connection or find a replacement!"
      sx={{
        marginRight: 0.5,
      }}
    >
      <WarningIcon />
    </Tooltip>
  );

  return (
    <div className={styles.root}>
      <Helmet>
        <title>CQ - {currentSession.data.name}</title>
      </Helmet>
      {importInProgress && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Split sizes={[65, 35]} minSize={50} gutterSize={20} className={styles.listWrapper}>
        <div ref={queueRef} className={styles.pane}>
          <SizeMe>
            {({ size: { width } }) => (
              <div className={styles.fullSize}>
                {(batchDeleteLoading || duplicationOperationLoading) && (
                  <BatchOperationInProgressModal
                    batchOperationType={
                      batchDeleteLoading ? BatchOperationType.Delete : BatchOperationType.Duplicate
                    }
                  />
                )}
                {isCardFocused && <CardView renderActionBar={renderActionBar} />}
                {width && width < 350 ? (
                  <div className={styles.collapsed}>Queue</div>
                ) : (
                  <>
                    <Paper
                      sx={(theme) => ({
                        display: 'flex',
                        backgroundColor: theme.palette.background.paper,
                      })}
                      square
                    >
                      {!hasSelectedCards && <TabsTitle label="Queue" />}
                      <Tabs
                        variant="scrollable"
                        sx={{
                          marginRight: 'auto',
                          flexGrow: 1,
                        }}
                        value={showSelectedTab ? QueueTabName.Selected : currentQueueTab}
                        onChange={(_, value: QueueTabName) => setCurrentQueueTab(value)}
                      >
                        {[
                          hasSelectedCards && (
                            <QueueTab
                              key="selected"
                              component="div"
                              style={{ opacity: 1 }}
                              value={QueueTabName.Selected}
                              label={
                                <span className={styles.tabLabel}>
                                  <span className={styles.tabCount}>
                                    {selectedCardIds.length}&nbsp;
                                  </span>
                                  {QueueTabName.Selected}&nbsp;
                                </span>
                              }
                            />
                          ),
                          batchModeActive && (
                            <QueueTab
                              key="batch"
                              component="div"
                              value={QueueTabName.Batch}
                              label={
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                  <IconButton
                                    sx={{ p: 0 }}
                                    size="small"
                                    onClick={deactivateBatchMode}
                                  >
                                    <CloseIcon sx={{ fontSize: 16 }} />
                                  </IconButton>
                                  &nbsp; Add Assets ({generatedCards.length})
                                </Box>
                              }
                            />
                          ),
                          showInProgressTab && (
                            <QueueTab
                              key="inProgress"
                              value={QueueTabName.InProgress}
                              label={
                                <span className={styles.tabLabel}>
                                  {hasInProgressCardsWithDeletedConnections &&
                                    deletedConnectionsWarning}
                                  {QueueTabName.InProgress}&nbsp;
                                  <span className={styles.tabCount}>
                                    (<InProgressCount />)
                                  </span>
                                </span>
                              }
                            />
                          ),
                          showDoneTab && (
                            <QueueTab
                              key="done"
                              value={QueueTabName.Done}
                              disabled={
                                currentQueueTab === QueueTabName.InProgress && hasSelectedCards
                              }
                              label={
                                <span className={styles.tabLabel}>
                                  {hasDoneCardsWithDeletedConnections && deletedConnectionsWarning}
                                  {numFilenamesNeedingAttention > 0 && (
                                    <>
                                      <Tooltip title="Some filenames for Assets marked &qout;Done&qout; have been modified!">
                                        <WarningIcon />
                                      </Tooltip>
                                      &nbsp;
                                    </>
                                  )}
                                  {QueueTabName.Done}&nbsp;
                                  <span className={styles.tabCount}>
                                    (<DoneCount />)
                                  </span>
                                </span>
                              }
                            />
                          ),
                          hasSelectedCards && (
                            <Button
                              key="takeAction"
                              variant="contained"
                              size="small"
                              color="primary"
                              onClick={() => kbar.query.toggle()}
                              sx={{ margin: (t) => t.spacing(1, 2) }}
                            >
                              Take Action
                            </Button>
                          ),
                        ]}
                      </Tabs>
                      <ModuleManager />
                    </Paper>
                    <Box mt={1}>{renderActionBar()}</Box>
                    {renderCurrentTab()}
                    <AddCardsModal container={queueRef.current} />
                  </>
                )}
              </div>
            )}
          </SizeMe>
        </div>
        <div className={styles.pane}>
          <SizeMe>
            {({ size: { width } }) => (
              <div className={styles.fullSize}>
                {width && width < 300 ? (
                  <div className={styles.collapsed}>Library</div>
                ) : (
                  <SourcePane />
                )}
              </div>
            )}
          </SizeMe>
        </div>
      </Split>
      <FilenameManager />
      <CardTitleManager />
      <QueueFolderDownloadModal />
      <FullScreenImageModal />
      <BatchCopyToClipboardModal />
    </div>
  );
});
