import { observer } from 'mobx-react-lite';
import { Button, ListSubheader } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ExpandedIcon from '@mui/icons-material/Rectangle';
import CollapseIcon from '@mui/icons-material/TableRows';

import { useStore } from 'logic';
import { ButtonPopover } from 'ui/ButtonPopover';
import { CardView } from 'logic/ui-active-session';
import { ModuleFieldsList } from './ModuleFieldsList';

export const QueueViewMenu = observer(() => {
  const {
    sessionsStore: { currentSession },
    uiActiveSessionStore: {
      queueViewMenuOpen,
      setQueueViewMenuOpen,
      areCardsExpanded,
      areCardsCollapsed,
      setCardView,
    },
  } = useStore();

  if (!currentSession) {
    return null;
  }

  return (
    <ButtonPopover
      button={
        <Button variant="outlined" size="small" startIcon={<VisibilityIcon />}>
          View
        </Button>
      }
      icon={<VisibilityIcon />}
      title="View"
      open={queueViewMenuOpen}
      requestOpen={() => setQueueViewMenuOpen(true)}
      requestClose={() => setQueueViewMenuOpen(false)}
    >
      <ListSubheader>Size</ListSubheader>
      <Button
        sx={{ marginRight: (t) => t.spacing(1) }}
        variant="contained"
        color={areCardsExpanded ? 'primary' : undefined}
        startIcon={<ExpandedIcon />}
        onClick={() => setCardView(CardView.expanded)}
      >
        Expanded
      </Button>
      <Button
        variant="contained"
        color={areCardsCollapsed ? 'primary' : undefined}
        startIcon={<CollapseIcon />}
        onClick={() => setCardView(CardView.collapsed)}
      >
        Collapsed
      </Button>
      <ListSubheader>Buckets and Data</ListSubheader>
      <ModuleFieldsList />
    </ButtonPopover>
  );
});
