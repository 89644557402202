import {
  ModuleBucketSize,
  ModuleConnectionDisplayMode,
  ModuleConnectionSize,
  SessionModule,
} from '@creative-kit/shared';
import { RootStore } from 'logic';
import { action, makeObservable } from 'mobx';

export type UserModuleDisplaySettings = {
  connectionsSize: ModuleConnectionSize;
};

export const getPercentageForSize = (size: ModuleBucketSize | ModuleConnectionSize): number => {
  switch (size) {
    case ModuleBucketSize.Full || ModuleConnectionSize.Full:
      return 100;
    case ModuleBucketSize.Half || ModuleConnectionSize.Half:
      return 47;
    case ModuleBucketSize.Compact || ModuleConnectionSize.Compact:
      return 32;
    default:
      return 100;
  }
};

export const getRemForSize = (size: ModuleConnectionSize): string => {
  switch (size) {
    case ModuleConnectionSize.Full:
      return '28rem';
    case ModuleConnectionSize.Half:
      return '22rem';
    case ModuleConnectionSize.Compact:
      return '18rem';
    default:
      return '';
  }
};

type ModuleDisplaySettings = {
  connectionsDisplayMode: ModuleConnectionDisplayMode;
  connectionsSize: ModuleConnectionSize;
  moduleBucketSize: ModuleBucketSize;
};

export class UiModuleConnectionDisplayStore {
  private rootStore: RootStore;
  private moduleDisplayModes: Map<string, ModuleDisplaySettings> = new Map();

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeObservable(this, {
      setConnectionDisplayMode: action.bound,
      getConnectionDisplayMode: action.bound,
      getModuleBucketSize: action.bound,
      setModuleBucketSize: action.bound,
      setConnectionsSize: action.bound,
      getConnectionsSize: action.bound,
    });
  }

  defaultModuleDisplaySettings: ModuleDisplaySettings = {
    connectionsDisplayMode: ModuleConnectionDisplayMode.Grid,
    connectionsSize: ModuleConnectionSize.Full,
    moduleBucketSize: ModuleBucketSize.Full,
  };

  setConnectionDisplayMode(module: SessionModule, displayMode: ModuleConnectionDisplayMode) {
    const { currentSession } = this.rootStore.sessionsStore;
    if (!currentSession) return;

    const moduleDisplaySettings =
      currentSession.getModuleDisplaySettings(module.id) || this.defaultModuleDisplaySettings;

    currentSession.setModuleDisplaySettings(module.id, {
      ...moduleDisplaySettings,
      connectionsDisplayMode: displayMode,
    });
  }

  getConnectionDisplayMode(moduleId: string): ModuleConnectionDisplayMode {
    const { currentSession } = this.rootStore.sessionsStore;
    const defaultMode = this.defaultModuleDisplaySettings.connectionsDisplayMode;

    if (!currentSession) return defaultMode;

    return currentSession.getModuleDisplaySettings(moduleId)?.connectionsDisplayMode || defaultMode;
  }

  setConnectionsSize(module: SessionModule, connectionsSize: ModuleConnectionSize) {
    const moduleDisplaySettings =
      this.moduleDisplayModes.get(module.id) || this.defaultModuleDisplaySettings;

    this.moduleDisplayModes.set(module.id, {
      ...moduleDisplaySettings,
      connectionsSize,
    });
  }

  getConnectionsSize(moduleId: string): ModuleConnectionSize {
    return this.moduleDisplayModes.get(moduleId)?.connectionsSize || ModuleConnectionSize.Full;
  }

  setModuleBucketSize(module: SessionModule, bucketSize: ModuleBucketSize) {
    const { currentSession } = this.rootStore.sessionsStore;
    if (!currentSession) return;

    const moduleDisplaySettings =
      currentSession.getModuleDisplaySettings(module.id) || this.defaultModuleDisplaySettings;

    currentSession.setModuleDisplaySettings(module.id, {
      ...moduleDisplaySettings,
      moduleBucketSize: bucketSize,
    });
  }

  getModuleBucketSize(moduleId: string): ModuleBucketSize {
    const { currentSession } = this.rootStore.sessionsStore;
    const defaultMode = this.defaultModuleDisplaySettings.moduleBucketSize;

    if (!currentSession) return defaultMode;

    return currentSession.getModuleDisplaySettings(moduleId)?.moduleBucketSize || defaultMode;
  }
}
