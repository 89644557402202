import { observer } from 'mobx-react-lite';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  darken,
  lighten,
  Paper,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Card } from 'logic/card';
import { WarningIcon } from 'ui/WarningIcon';
import { useStore } from 'logic';
import { Highlight } from 'ui/Highlight';
import { useFieldLabel } from 'ui/Formula/useFieldLabel';
import { useCallback, useState } from 'react';
import { CardStatus, Field, ModuleField } from '@creative-kit/shared';
import { isMac } from 'logic/utils';
import clsx from 'clsx';
import { useKBar } from 'kbar';
import { QueueCardContextMenu } from './QueueCardContextMenu';
import { StatusDot } from './CardStatusDot';
import { ChipFilterLabel } from './ChipFilterLabel';
import { SessionModule } from '@creative-kit/shared';

interface QueueCardTitleProps {
  card: Card;
}

const useStyles = makeStyles((theme) => ({
  title: {
    padding: theme.spacing(1, 1),
    fontSize: 24,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: darken(theme.palette.background.default, 0.2),
    '&:hover': {
      backgroundColor: darken(theme.palette.background.default, 0.3),
    },
  },
  selected: {
    backgroundColor: lighten(theme.palette.background.default, 0.125),
    '&:hover': {
      backgroundColor: lighten(theme.palette.background.default, 0.3),
    },
  },
  deleting: {
    opacity: 0.2,
  },
  cardNumber: {
    marginRight: theme.spacing(1),
  },
  labelWrapper: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    flexGrow: 1,
    minWidth: 0,
    marginRight: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
  labelChip: {
    padding: '0 6px',
  },
  label: {
    position: 'relative',
    color: theme.palette.background.default,
    borderRadius: '10px',
    textTransform: 'uppercase',
    fontSize: 24,
    fontWeight: 600,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'flex',
    minWidth: 0,
  },
  labelError: {
    backgroundColor: theme.palette.error.light,
  },
  titleErrors: {
    textTransform: 'uppercase',
  },
  doubleArrowIcon: {
    fontSize: '17px',
    position: 'absolute',
    top: -10,
    right: -10,
    transform: 'rotate(180deg)',
    borderRadius: '20px',
    border: '2px solid white',
    color: 'white',
  },
  filterListBadge: {
    padding: '2px',
    fontSize: '17px',
    position: 'absolute',
    top: -10,
    right: -10,
    borderRadius: '20px',
    color: 'black',
    backgroundColor: 'white',
  },
}));

export const QueueCardTitle = observer(({ card }: QueueCardTitleProps) => {
  const styles = useStyles();

  const {
    uiModulePowerColumnStore: { sourceModulePowerColumns },
    uiCardTitleStore: { openDialog: openCardTitleDialog },
    uiPermissions: { cannotEditCurrentSession },
    uiActiveSessionStore: {
      currentSourceTab,
      isCardSelected,
      selectAllOrBelow,
      selectedCardIds,
      selectCardsBetween,
      deselectAllCards,
      toggleExpandedCard
    },
    sessionCardActions: { completeCard, markCardInProgress },
    uiCardSearchStore: { hasSearch, searchTerms },
    sessionsStore: { currentSession },
  } = useStore();
  const kbar = useKBar();
  const getFieldLabel = useFieldLabel();
  const [isContextMenuOpen, setContextMenu] = useState(false);
  const [hoveringChipIndex, setHoveringChipIndex] = useState<number | undefined>();

  const labelPrefix = `${card.cardNumber}`;

  const hasCardTitle = card.mainLabel.length > 0;

  // const { toggleCardProgress } = currentSession!;

  const getTitle = () => {
    if (hasCardTitle === false) {
      return (
        <Button
          variant="outlined"
          onClick={openCardTitleDialog}
          fullWidth
          disabled={cannotEditCurrentSession}
        >
          Build Titles
        </Button>
      );
    }

    function isModuleField(field: Field): field is ModuleField {
      return field.type === 'module' && (field as ModuleField).moduleId !== undefined;
    }

    const getAssociatedModule = (field: Field): SessionModule | undefined => {
      return isModuleField(field) ? currentSession?.data.modules[field.moduleId] : undefined;
    };

    const getModuleColor = (field: Field): string | undefined => {
      const associatedModule = getAssociatedModule(field);
      return isModuleField(field) ? associatedModule?.color : undefined;
    };

    const getCurrentPowerColumn = (field: Field) => {
      return isModuleField(field) ? sourceModulePowerColumns[currentSourceTab] : undefined;
    };

    const getIsRelatedToCurrentSource = (field: Field): boolean | undefined => {
      const associatedModule = getAssociatedModule(field);
      const currentPowerColumn = getCurrentPowerColumn(field);
      const currentSelectedModule = currentPowerColumn?.selectedModule;
      return associatedModule?.id === currentSelectedModule?.id;
    };

    return (
      <Box
        display="flex"
        flexDirection="row"
        gap={1}
        sx={{
          // msOverflowStyle: 'none', // IE and Edge
          // scrollbarWidth: 'none', // Firefox
          // WebkitScrollbar: { display: 'none' },
          overflowX: 'auto',
          overflowY: 'visible',
          width: '100%',
          paddingTop: '10px',
          paddingBottom: '10px',
        }}
      >
        {currentSession?.data.cardTitleFormula.map((field, index) => {
          const computedField = currentSession.computeFormulaField(field, card, 0);
          const showMissingField = isModuleField(field) && !computedField;

          const associatedModule = getAssociatedModule(field);
          const moduleColor = getModuleColor(field);
          const currentPowerColumn = getCurrentPowerColumn(field);
          const isRelatedToCurrentSource = getIsRelatedToCurrentSource(field);

          // Ignore spacer fields
          if (!isModuleField(field) && !computedField) return null;
          const label = showMissingField ? (
            // If field is missing, display name of the Module Field that you need to fill out
            getFieldLabel(field)
          ) : hasSearch ? (
            <Highlight searchWords={searchTerms} textToHighlight={computedField ?? ''} />
          ) : (
            computedField
          );

          return (
            <Chip
              onMouseEnter={(_e: any) => setHoveringChipIndex(index)}
              onMouseLeave={(_e: any) => setHoveringChipIndex(undefined)}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              sx={{
                opacity: showMissingField ? 0.2 : 1.0,
                border: isRelatedToCurrentSource ? '2px solid white' : '',
                backgroundColor: (t) => moduleColor ?? t.palette.text.primary,
                ':hover': {
                  // Override default hover behavior
                  backgroundColor: (t) => moduleColor ?? t.palette.text.primary,
                },
                minWidth: '80px',
                flexShrink: 0,
                flowGrow: 1,
              }}
              classes={{
                label: styles.labelChip,
              }}
              className={styles.label}
              onClick={() => {
                // CopiableField was making the overflow logic here impossible to maintain
                navigator.clipboard.writeText(card.mainLabel);
              }}
              label={
                <ChipFilterLabel
                  isHoveringChip={hoveringChipIndex == index}
                  isRelatedToCurrentSource={isRelatedToCurrentSource}
                  isCardSelected={isCardSelected(card.id)}
                  currentPowerColumn={currentPowerColumn}
                  sourceModulePowerColumns={sourceModulePowerColumns}
                  associatedModule={associatedModule}
                  card={card}
                  moduleColor={moduleColor}
                  label={label}
                />
              }
            />
          );
        })}
      </Box>
    );
  };

  const handleCardSelect = useCallback(
    (e: React.MouseEvent, card: Card) => {
      e.stopPropagation();
      if (e.shiftKey) {
        e.preventDefault();
        selectCardsBetween(card.id);
      } else if ((isMac() && e.metaKey) || e.ctrlKey) {
        if (card.status === CardStatus.Completed) {
          markCardInProgress(card.id);
        } else {
          completeCard(card.id);
        }
      } else {
        card.toggleSelection();
      }
    },
    [card]
  );

  return (
    <Paper
      className={clsx(styles.title, 'mousetrap', {
        [styles.selected]: isCardSelected(card.id),
        [styles.deleting]: card.isDeleting,
      })}
      onContextMenu={(e) => {
        e.preventDefault();
        if (selectedCardIds.length > 1) {
          kbar.query.toggle();
        } else {
          setContextMenu(true);
        }
      }}
      onClick={() => {
        toggleExpandedCard(card.id);
      }}
      onKeyDown={(e) => {
        if (e.key === 'a' && e.metaKey) {
          e.preventDefault();
          selectAllOrBelow();
        }
        if (e.key === 'Escape') {
          e.preventDefault();
          deselectAllCards();
        }
      }}
    >
      <Checkbox
        color="default"
        onClick={(e) => handleCardSelect(e, card)}
        checked={card.isSelected}
      />
      <div className={styles.labelWrapper}>
        {card.isUpdating || card.isDeleting ? (
          <CircularProgress />
        ) : (
          <span className={styles.cardNumber}>{labelPrefix}</span>
        )}
        {getTitle()}
      </div>

      {/* TODO: only display on hover */}

      <StatusDot
        status={card.status}
        sx={{
          marginLeft: 'auto',
        }}
        label={
          <>
            {card.hasDeletedConnections && (
              <Tooltip
                title="Some connections have been deleted from their original source – Delete the connection or find a replacement!"
                sx={{
                  marginRight: 0.5,
                }}
              >
                <WarningIcon />
              </Tooltip>
            )}
            {card.filenameNeedsAttention && (
              <>
                <WarningIcon />
                &nbsp;
              </>
            )}
          </>
        }
      />

      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div onClick={(e) => e.stopPropagation()} role="button" tabIndex={0}>
        <QueueCardContextMenu card={card} open={isContextMenuOpen} setOpen={setContextMenu} />
      </div>
    </Paper>
  );
});
